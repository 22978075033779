@import url("https://fonts.googleapis.com/css2?family=Akshar&family=Montserrat&family=Roboto&family=Tiro+Devanagari+Hindi&display=swap");

@font-face {
  font-family: "LohitGujrati";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./gujratiFonts/Lohit-Gujarati.ttf");
}

@font-face {
  font-family: "Hind Gujrati";
  font-style: bold;
  font-weight: 600;
  font-display: swap;
  src: url("./gujratiFonts/Hind-Gujarati.ttf");
}

@font-face {
  font-family: "Hind Gujrati BOLD";
  font-style: bold;
  font-weight: 800;
  font-display: swap;
  src: url("./gujratiFonts/Hind-Gujarati-Bold.ttf");
}

@font-face {
  font-family: "aakar";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./gujratiFonts/Aakar.ttf") format("trutype"),
}

@font-face {
  font-family: "padmaa";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./gujratiFonts/Padmaa.ttf") format("trutype");
}

@font-face {
  font-family: "rajkot";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./gujratiFonts/Rajkot.ttf") format("trutype");
}

@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./englishFonts/Arimo-Regular.ttf") format("trutype");
}
