.invitation-message-preview {
  .image-preview-wrapper {
    height: 200px;
    overflow: hidden;
    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }
    .textLayer {
      display: none !important;
    }

    .annotationLayer {
      display: none !important;
    }
    .image-preview {
      width: 300px;
    }
  }
  .invitation-message-wrapper {
    max-height: 400px;
    .invitation-message pre {
      margin-bottom: 0;
      white-space: pre-wrap;
      font-family: system-ui;
    }
  }
}
