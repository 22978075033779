.variable-box-wrapper {
  .variable-box {
    min-height: 55px;
    min-width: 254px;
    box-shadow: 2px 5px 28px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    cursor: pointer;
    .dots-wrapper {
      margin-left: 9px;
      margin-right: 17px;
      .left-dots {
        .dot {
          height: 4.1px;
          width: 4.1px;
          background: #eaeaea;
          border-radius: 50%;
          margin-top: 1px;
          margin-right: 1px;
        }
      }
      .right-dots {
        .dot {
          height: 4.1px;
          width: 4.1px;
          background: #eaeaea;
          border-radius: 50%;
          margin-top: 1px;
        }
      }
    }
  }
}

.variable-box-alignment-wrapper {
  .variable-box-alignment-icon {
    background-color: white;
    padding: 5px 15px;
    border-top: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer !important;
  }

  .checkbox-input {
    cursor: pointer;
  }
}
