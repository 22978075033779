.sign-in-form-wrapper {
  width: 100%;
  max-width: 1085px;
  height: 100%;
  max-height: 838px;
  background-color: white;
  border-radius: 30px;
  .sign-in-form {
    max-height: 573px;
    height: 100%;
    max-width: 784px;
    width: 100%;
    border: 1px dashed #333333;
    border-radius: 16px;
    .custom-input {
      height: 100%;
      width: 100%;
      max-height: 78px;
      max-width: 345px;
      border-radius: 17px;
    }
    .sign-in-button {
      height: 100%;
      width: 100%;
      max-height: 78px;
      max-width: 345px;
      border-radius: 17px;
    }
  }
}
