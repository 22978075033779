.create-invitation-wrapper {
  height: 100%;
  // max-height: 838px;
  max-height: 720px;
  // padding: 50px;
  width: 100%;
  max-width: 878px;
  background-color: white;
  border-radius: 30px;
  .back-button {
    width: 141px;
    height: 78px;
    border-radius: 17px;
  }
  .w-784 {
    width: 100%;
    max-width: 784px;
  }
  .upload-file-wrapper {
    width: 100%;
    max-width: 784px;
    height: 100%;
    max-height: 487px;
  }
  .next-button {
    width: 141px;
    // height: 78px;
    border-radius: 17px;
  }
  .text-title {
    font-size: 35px;
    color: #000000;
  }
  @media screen and (max-width: 768px) {
    .text-title {
      font-size: 34px;
    }
    .next-button-wrapper {
      flex-direction: column;
    }
    .next-button {
      width: 100%;
      margin-top: 20px;
    }
  }
}
