.custom-input-wrapper {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  border: 1px #333 dashed;
  outline: 17px solid white;
}

.select-file-button {
  width: 210px !important;
  height: 80px !important;
  background-color: #f6f6f6 !important    ;
  border-radius: 13px !important;
}
.upload-data-button {
  width: 180px;
  height: 68px;
  background-color: #f6f6f6 !important    ;
  border-radius: 13px !important;
}

.file-preview-wrapper {
  max-width: 300px;
  max-height: 425px;
  overflow: auto;
  .image-preview {
    object-fit: contain;
  }
  .render-pdf-wrapper {
    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }
    .textLayer {
      display: none !important;
    }

    .annotationLayer {
      display: none !important;
    }
    .pdf-page {
      margin-bottom: 10px;
    }
  }
}

.guest-data-wrapper {
  max-width: 684px;
  width: 100%;
  max-height: 440px;
  height: auto;
  overflow: auto;
}

// ::-webkit-scrollbar {
//   display: none;
// }
