.variable-customization {
  .back-button {
    width: 141px;
    height: 53px;
    // background: #fff;
    background: transparent;

    border: 1px solid #333333;
    border-radius: 17px;
    font-size: 18px;
  }
  .preview-button {
    // width: 100%;
    // max-width: 201px;
    // height: 78px;
    // border-radius: 17px;
    // margin-right: 25px;
    width: 141px;
    height: 53px;

    border: 1px solid #333333;
    border-radius: 17px;
    font-size: 18px;
  }
  .above-buttons-wrapper {
    max-width: 1085px;
    width: 100%;
  }

  .variable-insertion-wrapper {
    max-width: 1085px;
    // width: 100%;
    max-height: 750px;
    height: 100%;
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    .left-section {
      width: 100%;
      max-width: 600px;
      height: 100%;
      max-height: 750px;
      overflow: auto;
      .render-pdf-wrapper {
        .textLayer {
          display: none !important;
        }
        .react-pdf__Page__canvas {
          margin: 0 auto;
        }

        .annotationLayer {
          display: none !important;
        }
        .pdf-page {
          margin-bottom: 10px;
        }
      }
      .dropable-div {
        width: fit-content;
        height: fit-content;
      }
      .scalling-btn-wrapper {
        position: absolute;
        top: 10px;
        z-index: 1;
        gap: 10px;

        .scalling-btn {
          background-color: #fff;
          border-radius: 10px;
          border: none;
          padding: 5px 24px;
          text-rendering: auto;
          box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
            rgba(0, 0, 0, 0.22) 0px 15px 12px;
        }
      }
    }
    .right-section {
      // padding: 30px 57px 56px 0;
      padding: 30px;
      .variable-boxes-wrapper {
        max-height: 565px;
        overflow: auto;
        padding: 0px 25px 25px 25px;
      }
      .variable-customization-wrapper {
        max-height: 480px;
        overflow: auto;
        padding: 0px 25px 25px 25px;
      }

      .zip-button {
        width: 307px;
        height: 78px;
        border-radius: 17px;
        .zip-icon-wrapper {
          width: 34px;
          height: 46px;
          margin-left: 23px;
          margin-right: 12px;
        }
      }
      .whatsapp-button {
        margin-top: 12px;
        width: 307px;
        height: 78px;
        border-radius: 17px;
        .whatsapp-icon {
          margin-right: 6px;
          margin-left: 18px;
        }
      }
      .next-button {
        width: 141px;
        height: 78px;
        border-radius: 17px;
      }
    }
  }
}

.transform-component-module_wrapper__SPB86 {
  max-width: 100%;
  height: inherit !important;
}

// @media (max-width: 1085px) {
//   .variable-insertion-wrapper {
//     flex-direction: column;
//   }

//   .right-section {
//     padding: 0 !important;
//     align-items: center !important;
//     justify-content: center !important;
//     width: 100% !important;
//   }
// }

// .custom-message-buttons {
//   position: absolute;
//   bottom: 55px;
// }

.whatsapp-button:hover {
  background-color: #0064e2;
}

// .zip-and-whatapp-button {
//   position: absolute;
//   bottom: 75px;
// }

@media (max-width: 768px) {
  .variable-insertion-wrapper {
    flex-direction: column;
  }

  // .custom-message-buttons {
  //   position: inherit;
  // }

  // .zip-and-whatapp-button {
  //   position: inherit;
  // }

  .right-section {
    padding: 0 !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
  }

  .left-section {
    max-width: 100% !important;
  }

  .variable-customization
    .variable-insertion-wrapper
    .right-section
    .whatsapp-button {
    width: 100% !important;
    height: 50px !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 14px !important;
    padding: 0 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px !important;
    background-color: #0064e2 !important;
    color: #fff !important;
  }

  .skip-button {
    border: 1px solid #0064e2 !important;
    background-color: #fff !important;
    color: #0064e2 !important;
  }
  .variable-customization
    .variable-insertion-wrapper
    .right-section
    .variable-customization-wrapper {
    max-height: 300px;
  }

  .preview-button {
    width: 100% !important;
    // max-width: 100% !important;
    height: 50px !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 14px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .above-buttons-wrapper {
    justify-content: space-between !important;
    margin-bottom: 20px !important;
  }

  .back-button {
    width: 100% !important;
    height: 50px !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 18px !important;
  }

  .header-preview-button {
    width: 150px !important;
    height: 40px !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 18px !important;
  }
}
