@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.app {
  font-family: "Poppins", sans-serif;
}

.flex {
  display: flex;
}

// font classes :start

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-25 {
  font-size: 25px;
}
.fs-32 {
  font-size: 32px !important;
}
.fs-35 {
  font-size: 35px;
}
.fs-38 {
  font-size: 38px;
}
.fs-48 {
  font-size: 48px;
}
.fs-50 {
  font-size: 50px;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
// font classes :end

// margin classes :start
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-22 {
  margin-top: 22px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-52 {
  margin-top: 52px;
}
.mt-56 {
  margin-top: 56px;
}
.mt-58 {
  margin-top: 58px;
}
.mt-69 {
  margin-top: 69px;
}
.mt-83 {
  margin-top: 83px;
}
.ml-7 {
  margin-left: 0.438rem;
}
.ml-69 {
  margin-left: 69px;
}
.mr-37 {
  margin-right: 37px;
}
.mr-41 {
  margin-right: 41px;
}
.mr-182 {
  margin-right: 182px;
}
.mr-213 {
  margin-right: 213px;
}
// margin classes :end

// padding classed :start
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.pt-10 {
  padding-top: 0.625rem;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-52 {
  padding-top: 52px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-17 {
  padding-right: 17px;
}
.px-10 {
  padding: 0 10px;
}
.px-19 {
  padding: 0 19px;
}
.py-10 {
  padding: 10px 0;
}

.py-20 {
  padding: 20px 0;
}

.py-59 {
  padding-top: 59px;
  padding-bottom: 59px;
}

// padding classed :end

// transform classes :start
.transform-180 {
  transform: rotate(180deg);
}

// transform classes :end

// button classes :start
.btn-yellow {
  background-color: #ffca55;
}

// button classes :end

// height classes :start

.h-0 {
  height: 0;
}
.h-100vh {
  height: 100vh;
}

// height classes :end

// width classes :start

.w-0 {
  width: 0;
}
.w-345 {
  max-width: 345px;
  width: 100%;
}
.w-fit-content {
  width: fit-content;
}
// width classes :end

.bg-yellow {
  background-color: #ffca55;
}

// Custom ScrollBar
.custom-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 5px;
    margin-left: 5px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
}

// cursor-classes :start
.cursor-pointer {
  cursor: pointer !important;
}
// cursor-classes :end

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
