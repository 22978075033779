.message-writer-wrapper {
  .message-writer-toolbar {
    min-height: 20px;
    border: 1px solid #ced4da;
    border-bottom: none;
  }
  #message-writer {
    width: 100%;
    border: 1px solid #ced4da;
    font-size: 13px !important;
    min-height: 219px;
    height: fit-content;
    max-height: 445px;
    &:focus-visible {
      border: 1px solid #ced4da;
      outline: 0;
    }
  }
}
