.invitation-message-wrapper {
  .back-button {
    width: 141px;
    height: 78px;
    background: #fff;
    border: 1px solid #333333;
    border-radius: 17px;
  }
  .message-write-section-wrapper {
    height: calc(100% - 94px);
    .message-write-section {
      width: 70%;
      background-color: white;
      border-radius: 30px;
      height: 100%;

      .invitation-message-editor {
        .ql-editor {
          min-height: 250px;
          direction: ltr !important;
        }
        .ql-toolbar {
          border-radius: 13px 13px 0px 0px;
        }
        .ql-container {
          border-radius: 0px 0px 13px 13px;
        }
      }
    }
  }

  .message-write-section-wrapper-update {
    height: calc(100% - 0px) !important;
  }

  .message-preview-section {
    width: 29%;
    background-color: white;
    border-radius: 30px;
    height: 100%;
    .zip-button {
      width: 215px;
      height: 60px;
      border-radius: 17px;
      .zip-icon-wrapper {
        width: 22px;
        // height: 46px;
        margin-left: 14px;
        margin-right: 12px;
      }
    }
    .whatsapp-button {
      width: 215px;
      height: 60px;
      border-radius: 17px;
      .whatsapp-icon-wrapper {
        width: 26px;
        margin-right: 6px;
        margin-left: 14px;
        .whatsapp-icon {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .invitation-title-input:focus {
    outline: none;
  }
}

@media screen and (max-width: 1024px) {
  .message-write-section {
    width: 100% !important;
    height: auto !important;
  }

  .invitation-message-wrapper {
    overflow-y: auto;
  }

  .message-preview-section {
    padding-bottom: 30px;
  }

  .message-preview-section {
    width: 100% !important;
    height: auto !important;
  }

  .message-write-section-wrapper {
    gap: 20px;
  }

  .whatsapp-button {
    width: 100% !important;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    justify-content: space-around;
    height: 50px !important;
  }

  .zip-button {
    width: 100% !important;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    justify-content: space-around;
    height: 50px !important;
  }

  .back-button {
    width: 100px !important;
    height: 40px !important;
    padding: 0px !important;
  }
}
