table {
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
tr {
  border: 1px solid #efefef;
}
td {
  border: 1px solid #efefef;
  padding: 10px 30px;
  background-color: #ffffff;
  color: #000000;
}
th {
  border: 1px solid #efefef;
  padding: 10px 30px;
  background-color: #ffffff;
  color: #000000;
}

tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
tr:first-child th:last-child {
  border-top-right-radius: 10px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.active-row {
  background-color: #bfbbb4;

  td {
    background-color: transparent;
  }
}
