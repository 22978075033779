// .rapid-sender-logo {
//   margin-left: 171px;
//   @media screen and (max-width: 768px) {
//     margin-left: 50px;
//   }
// }

// .brand-text {
//   font-size: 32px;
//   @media screen and (max-width: 768px) {
//     font-size: 18px;
//   }
// }

// .rapid-sender-logo {
//   margin-left: 171px;
//   @media screen and (max-width: 768px) {
//     margin-left: 50px;
//   }
// }

// .navbar-main {
//   border-top: 8px solid #db9807;
// }

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.rapid-sender-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.brand-text {
  font-size: 20;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.brand-text-description {
  font-size: 14px;
}

.link-color {
  color: #555555;
}

.login-button {
  background-color: #db9807;
  border-color: #db9807;
  font-weight: 500;
}

@media (max-width: 768px) {
  .offcanvas-login-button {
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
    padding: 0 20px;
    width: 100%;
  }

  .offcanvas-login-button .login-button {
    width: 100%;
  }

  .offcanvas-navbar-nav {
    gap: 10px;
  }

  .landing-page-navbar-container .navbar-toggler {
    border: none;
  }

  .landing-page-navbar-container .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
}

.btn:hover {
  // color: #db9807;
  background-color: #db9807;
  border-color: #db9807;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  // color: #db9807;
  background-color: #db9807;
  border-color: #db9807;
}

// .offcanvas-navbar {
//   flex-direction: column-reverse;
//   gap: 20px;
// }
