.color-picker-wrapper {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 24px;
  margin-left: 16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  overflow: hidden;
  .color-picker {
    border: 0;
    width: 100%;
    height: auto;
    position: relative;
    opacity: 0;
    top: 21px;
  }
}
