.invitation-status-wrapper {
  width: fit-content;
  overflow: auto;
  margin: 0 auto;
  .back-button {
    width: 141px;
    height: 78px;
    border-radius: 17px;
  }
}

.data-button {
  height: 36px;
}

.excel-data-wrapper {
  padding: 10px 10px 0px 10px;
}

.qr-code-wrapper {
  padding: 15px;
  background-color: #fff;
  width: fit-content;
  border-radius: 10px;
}

.logged-in-message {
  background: white;
  border-radius: 5px;
  text-align: center;
}

.qr-code-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;

  .qr-code-container {
    position: relative;
    width: 255px;
    height: 255px;
  }

  .qr-code-background {
    width: 100%;
    height: 100%;
    // background-color: #f2f2f2;
    background-color: #fff;
    border-radius: 6px;
  }

  .qr-code-shimmer {
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      -45deg,
      #ececec 0%,
      #f9f9f9 20%,
      #ececec 40%,
      #ececec 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 200%;
    animation: shimmer-animation 1s infinite;
  }

  @keyframes shimmer-animation {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}

@media screen and (max-width: 1085px) {
  .invitation-status-wrapper {
    flex-direction: column;
  }
  .invitation-id-text {
    font-size: 14px !important;
  }
  // .back-button {
  //   font-size: 14px;
  //   padding: 0 10px !important;
  // }

  .excel-buttons-wrapper {
    margin-bottom: 10px;
  }

  // .back-button {
  //   padding: 4px 8px !important;
  // }

  .navbar-main {
    border: none !important;
  }

  .table-wrapper {
    // max-height: 486px !important;
    width: 100%;
  }
}

.table-wrapper {
  // max-height: 750px !important;
  padding-top: 10px;
}

// whatsapp loader

/* HTML: <div class="loader"></div> */
.loader {
  height: 4px;
  width: 130px;
  --c: no-repeat linear-gradient(#808080 0 0);
  background: var(--c), var(--c), #c4a869;
  background-size: 60% 100%;
  animation: l16 3s infinite;
}
@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
}
