.desc_block {
  width: 100%;
  height: auto;
}

.rederHTML table {
  height: auto !important;
}
.rederHTML p {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
