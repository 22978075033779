.variable-customization {
  box-shadow: 2px 5px 28px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px 8px;
  .variable-box {
    // min-height: 55px;
    min-width: 254px;
    // background: #ffffff;
    background: #f7f7f7d3;
    border-radius: 8px;
    .dots-wrapper {
      margin-left: 9px;
      margin-right: 17px;
      .left-dots {
        .dot {
          height: 4.1px;
          width: 4.1px;
          background: #eaeaea;
          border-radius: 50%;
          margin-top: 1px;
          margin-right: 1px;
        }
      }
      .right-dots {
        .dot {
          height: 4.1px;
          width: 4.1px;
          background: #eaeaea;
          border-radius: 50%;
          margin-top: 1px;
        }
      }
    }
    .page-no {
      height: 26px;
      width: 26px;
      border-radius: 50%;
      background-color: #eaeaea;
    }
  }
  .font-family-handler {
    margin-top: 8px;
    width: 254px;
    height: 54.62px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    .custom-form-select {
      max-width: 150px;
      text-overflow: ellipsis;
      .select-divider {
        text-transform: capitalize;
        background-color: lightgray;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
  }

  .font-style-handler {
    margin-top: 8px;
    width: 254px;
    height: 54.62px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
  }

  .color-picker-handler {
    margin-top: 8px;
    width: 254px;
    height: 41.62px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    .color-preview {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 24px;
      margin-left: 16px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }
  .font-size-handler {
    margin-top: 8px;

    width: 254px;
    height: 54.62px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}
